import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video/kebab"
import VideoMobile from "../../components/Video/kebab-mobile"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import ControlPanel from "../../components/ControlPanel/new"
import ControlPanelMobile from "../../components/ControlPanel/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

// ASSETS
import ElectramDetailVideo from "../../assets/videos/Electram/electram-detail-1.mp4"
import ElectramDetailVideoMobile from "../../assets/videos/Electram/mobile/electram-video-detail-1.mp4"

const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  
  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
      />
      {!breakpoints.as && (
        <>
          <Video
            info={t('video_intro', { returnObjects: true })}
            image={t('video_intro', { returnObjects: true })['image']}
            id="electramkebab-hero"
            toClick="#electramkebab-intro"
          />
          <Introduction
            className="scrollable"
            id="electramkebab-intro"
            translation={t('intro', { returnObjects: true })}
          />
          <Carousel
            id="electramkebab-carousel"
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            carouselContent={t('carousel', { returnObjects: true })}
          />
          <VideoDetail
            id="electramkebab-videodetail"
            src={ElectramDetailVideo}
            poster="https://ramalhos.com/video_posters/Electram/electram-detail-1.jpg"
            _title={t('feature_01', { returnObjects: true })['title']}
            text1={t('feature_01', { returnObjects: true })['text']}
          />
          <FeatureLine
            id="electramkebab-feature01"
            _title={t('feature_02', { returnObjects: true })['title']}
            text={t('feature_02', { returnObjects: true })['text']}
            image={t('feature_02', { returnObjects: true })['image']}
            positionCenter
            midBrightness
            textWidth="90vw"
          />
          <FeatureLine
            id="electramkebab-feature02"
            _title={t('feature_03', { returnObjects: true })['title']}
            text={t('feature_03', { returnObjects: true })['text']}
            image={t('feature_03', { returnObjects: true })['image']}
            textWidth="520px"
            growUp
          />
          <FeatureLine
            id="electramkebab-feature03"
            _title={t('panel_intro', { returnObjects: true })['title']}
            text={t('panel_intro', { returnObjects: true })['text']}
            positionCenter
          />
          <ControlPanel
            id="electramkebab-controlpanel"
            carouselContent={t('panel', { returnObjects: true })['panels']}
            _title={{ title1: t('panel', { returnObjects: true })['title1'], title2: t('panel', { returnObjects: true })['title2'] }}
            defaultImage={t('panel', { returnObjects: true })['panels']['default']}
          />
          <FeatureLine
            id="electramkebab-feature04"
            _title={t('feature_04', { returnObjects: true })['title']}
            text={t('feature_04', { returnObjects: true })['text']}
            image={t('feature_04', { returnObjects: true })['image']}
            positionCenter
            textWidth="90vw"
          />
          <FeatureLine
            id="electramkebab-feature05"
            _title={t('feature_05', { returnObjects: true })['title']}
            image={t('feature_05', { returnObjects: true })['image']}
            titleWidth="500px"
            marginLeft="700px"
          />
        </>
      )}
      {breakpoints.as && (
        <>
          <VideoMobile
            id="banner-video-mobile"
            info={t('video_intro', { returnObjects: true })}
            image={t('video_intro', { returnObjects: true })['imageM']}
            toClick="#electramkebab-intro"
          />
          <Introduction
            className="scrollable"
            id="electramkebab-intro"
            translation={t('intro', { returnObjects: true })}
          />
          <CarouselMobile
            id="electramkebab-carousel-mobile"
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            carouselContent={t('carousel', { returnObjects: true })}
            alt={true}
          />
          <VideoDetailMobile
            id="electramkebab-feature01-mobile"
            src={ElectramDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/Electram/mobile/electram-video-detail-1.jpg"
            _title={t('feature_01', { returnObjects: true })['title']}
            text={t('feature_01', { returnObjects: true })['text']}
            positionAbsolute
          />
          <FeatureLineMobile
            id="electramkebab-feature02-mobile"
            _title={t('feature_02', { returnObjects: true })['title']}
            text1={t('feature_02', { returnObjects: true })['textM']}
            image={t('feature_02', { returnObjects: true })['image']}
            positionCenter
          />
          <FeatureLineMobile
            id="electramkebab-feature03-mobile"
            _title={t('feature_03', { returnObjects: true })['title']}
            text1={t('feature_03', { returnObjects: true })['textM']}
            image={t('feature_03', { returnObjects: true })['image_mobile']}
            positionTopBottom
          />
          <ControlPanelMobile
            id="electramkebab-control-panel-mobile"
            _title={{ title1: t('panel', { returnObjects: true })['title1'], title2: t('panel', { returnObjects: true })['title2'] }}
            carouselContent={t('panel', { returnObjects: true })['panels']}
          />
        </>
      ) // Mobile
      }
      <SpecsTable
        id="specs-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="190px"
      />
        {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryElectramKebabPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["kebab", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
